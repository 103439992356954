import React from 'react';
import './footer.css'

class Footer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      //this.gestoreClick = this.gestoreClick.bind(this);
    }
    render() {
      return (
        <footer className="main-footer">
            <strong>Copyright &copy; 2020 <a href="https://mbility.it">MBility</a>.</strong>
            &nbsp;All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.8.2
            </div>
        </footer>
      );
    }
  }
  
  export default Footer;
  