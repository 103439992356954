import axios from 'axios';

export function log(action) {
    if(action) {
        window.firebase.auth().currentUser.getIdToken(true).then(function(token) {
            axios.post('https://api.mbility.eu/api/addLog', {
                'application': 'GESTIONALE',
                'token': token,
                'action': action
            })
        });
    }
}